<template>
    <div class="mcontainer">
        <div>
            <router-link :to="{ path: '/app/group/'+ detail.group_code + '/discussion' }"><Icon icon="dashicons:arrow-left-alt2" class="d-inline mr-2" />Back to topic</router-link>
            <div class="card lg:mx-0 uk-animation-slide-bottom-small mt-4">
                <div class="profile is_group mb-0">
                    <div class="profiles_banner discussion-banner">
                        <h1 class="text-lg title-bold text-white">{{ detail.title }}</h1>
                        <img :src="detail.group_banner ? detail.group_banner : require('@/assets/images/avatars/profile-cover.png')" alt="">
                    </div>
                </div>
                <div class="flex justify-between items-center lg:p-4 p-2.5">
                    <div class="flex flex-1 items-center space-x-4">
                        <a href="#">
                            <img :src="detail.author.images !== '' && detail.author.images !== null ? detail.author.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                        </a>
                        <div class="flex-1 font-semibold">
                            <a href="#" class="text-black dark:text-gray-100 capitalize"> {{ detail.author.fullname }} </a>
                            <div class="flex items-center space-x-2 small"> {{ formatDateFromNow(detail.created_at) }}</div>
                        </div>
                    </div>
                    <b-dropdown class="flex items-center btn-no-style" v-if="detail.is_owner || detail.is_member" no-caret>
                        <template #button-content>
                            <i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>
                        </template>
                        <b-dropdown-item v-if="detail.is_owner" @click.prevent="editThread"><i class="uil-edit-alt mr-1"></i>  Edit Post</b-dropdown-item>
                        <b-dropdown-item v-if="detail.disable_comments" @click.prevent="onEnableComment"><i class="uil-comment mr-1"></i> Enable comments</b-dropdown-item>
                        <b-dropdown-item v-else @click.prevent="onDisableComment"><i class="uil-comment-slash mr-1"></i> Disable comments</b-dropdown-item>
                        <b-dropdown-item v-if="detail.is_owner" @click.prevent="deleteDiscussion"><i class="uil-trash-alt mr-1"></i>  Delete</b-dropdown-item>
                    </b-dropdown>
                </div>

                <div class="pl-4 pr-4 pb-4">
                    <p v-if="detail.topics && detail.topics.length" class="subtitle">Topics: {{ detail.topics.map(i => i.title).join(', ') }}</p>
                    <div v-html="detail.content" v-linkified></div>
                </div>

                <div class="p-4 space-y-3">
                    <comment-list
                        :comments="detail.comments"
                        :comments-count="detail.comments_count"
                        :likes="detail.likes"
                        :likes-count="detail.likes_count"
                        :disable-comment="detail.disable_comments"
                        :add-comment="onComment"
                    />
                </div>
                <form-discussion
                    :group-id="detail.group_code"
                    :loading="thread.form.loading"
                    v-model="thread.form.data"
                    @submit="submitThread"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex'
    import CommentList from '@/components/CommentList'
    import { Icon } from '@iconify/vue2'
    import FormDiscussion from '@/components/FormDiscussion'

    export default {
        components: { FormDiscussion, CommentList, Icon },
        computed: {
            activeNavigation () {
                return this.$route.name
            },
            ...mapState('discussions', {
                detail: state => state.selected.data,
                loading: state => state.selected.loading
            })
        },
        data () {
            return {
                groupNavigations: [
                    { route: 'GroupTimeline', label: 'Timeline' },
                    { route: 'GroupDiscussion', label: 'Discussion' },
                    { route: 'GroupAbout', label: 'About Group' },
                    { route: 'GroupFile', label: 'Files' },
                    { route: 'GroupMember', label: 'Members' }
                ],
                thread: {
                    form: {
                        loading: false,
                        show: false,
                        inputTags: '',
                        data: {
                            title: '',
                            content: '',
                            topics: [],
                            disable_comments: false
                        }
                    }
                },
                hidedropdown: true
            }
        },
        created () {
            this.fetch()
        },
        methods: {
            ...mapActions('discussions', ['show', 'addComment', 'delete', 'enableComment', 'disableComment', 'update']),
            ...mapActions('topics', {
                _fetchTopic: 'fetch'
            }),
            fetch () {
                this.show(this.$route.params.slug)
                    // eslint-disable-next-line no-return-assign
                    .then(({ id, title, content, topics }) => this.thread.form.data = {
                        id,
                        title,
                        content,
                        topics
                    }).finally(() => {
                        this._fetchTopic({ group_id: this.detail.group_code })
                    })
            },
            onEnableComment () {
                this.enableComment(this.$route.params.slug)
                    .then(() => this.fetch())
            },
            onDisableComment () {
                this.disableComment(this.$route.params.slug)
                    .then(() => this.fetch())
            },
            onComment ({ content, parentId, attachments }) {
                return this.addComment({
                    slug: this.$route.params.slug,
                    content,
                    parentId,
                    attachments
                })
            },
            deleteDiscussion () {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.delete(this.$route.params.slug)
                            .then((data) => this.$router.push({ name: 'GroupDiscussion', params: { code: data.group_slug } }))
                            .catch((e) => console.log(e))
                    }
                })
            },
            editThread () {
                this.$bvModal.show('modal-form-discussion')
                // console.log(this.detail.group_code)
            },
            submitThread () {
                this.thread.form.loading = true
                const { title, content, topics } = this.thread.form.data

                this.update({ id: this.detail.slug, data: { title, content, topics } }).then(() => {
                    this.fetch()
                    this.hidedropdown = true
                    this.thread.form.show = false
                    this.thread.form.data = { id: '', title: '', content: '', topics: [], disable_comments: false }
                    this.$bvModal.hide('modal-form-discussion')
                })
                    .finally(() => {
                        this.thread.form.loading = false
                    })
            }
        }
    }
</script>
