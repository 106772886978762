<template>
    <b-modal id="modal-form-discussion" title="Create New Thread" ok-title="Submit" :hide-footer="true">
        <form @submit.prevent="$emit('submit')">
            <b-form-group>
                <b-form-input
                    v-model="modelTitle"
                    placeholder="Add a title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-textarea
                    v-model="modelContent"
                    placeholder="Add a Content"
                    rows="3"
                    required
                ></b-form-textarea>
            </b-form-group>
            <b-form-group>
                <b-select class="form-control" v-model="modelTopics" required>
                    <b-select-option v-for="item in dataTopics" :key="item.id" :value="item.id">{{ item.title }}</b-select-option>
                </b-select>
            </b-form-group>
            <b-form-group align="right">
                <div class="d-flex justify-content-between">
                    <b-form-checkbox v-model="modelDisableComments" name="check-button" switch>
                        Disable comments
                    </b-form-checkbox>
                    <div>
                        <b-button variant="primary" type="submit" size="sm">
                            Submit
                        </b-button>
                    </div>
                </div>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        props: {
            groupId: {
                type: [Number, String],
                default: ''
            },
            topicId: {
                type: [Number, String],
                default: ''
            },
            loading: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object,
                default: () => ({
                    topics: []
                })
            }
        },
        data () {
            return {
                topics: ''
            }
        },
        computed: {
            ...mapState('topics', {
                dataTopics: state => state.data
            }),
            modelTitle: {
                get () {
                    return this.value.title
                },
                set (title) {
                    this.$emit('input', { ...this.value, title })
                }
            },
            modelContent: {
                get () {
                    return this.value.content
                },
                set (content) {
                    this.$emit('input', { ...this.value, content })
                }
            },
            modelTopics: {
                get () {
                    if (this.value.topics.length > 0) return this.value.topics.map(i => i.id ? i.id : i)[0]
                    return ''
                },
                set (topics) {
                    this.$emit('input', { ...this.value, topics: [topics] })
                }
            },
            modelDisableComments: {
                get () {
                    return this.value.disable_comments
                },
                set (disableComments) {
                    this.$emit('input', { ...this.value, disable_comments: disableComments })
                }
            }
        },
        methods: mapActions('topics', ['fetch']),
        mounted () {
            if (this.groupId !== '') {
                this.fetch({ group_id: this.groupId })
            }
        }
    }
</script>
